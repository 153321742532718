export const BASE_URL = window.location.host.includes('localhost') ? 'http://localhost:3001' : window.location.origin;
// export const BASE_URL = 'http://172.20.10.5:3001';

export const detectDeviceType = () => {
	const userAgent = window.navigator.userAgent;
	if (/android/i.test(userAgent)) {
		return 'Android';
	} else if ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || navigator.platform === 'iPhone' ||
		navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC' ||
		navigator.platform === 'iPad' || navigator.platform === 'iPod') {
		return 'iOS';
	} else {
		return 'unknown';
	}
};

export const device = detectDeviceType().toLowerCase();
export const HEIGHT_EXPANDED = 424;
export const HEIGHT_DEFAULT = 332;

const sendIOSMessage = (name, message) => {
	if(window['webkit'] && window['webkit']['messageHandlers'] && window['webkit']['messageHandlers']['platformInterface']) {
		window['webkit']['messageHandlers']['platformInterface'].postMessage(JSON.stringify({
			"action": name, "script": message, "url": message
		}));
	} else if(window['webkit'] && window['webkit']['messageHandlers'] && window['webkit']['messageHandlers'][name]){
		window['webkit']['messageHandlers'][name].postMessage(message);
	} else if(window['webkit'] && window['webkit']['messageHandlers']){
		console.warn(`window.webkit.messageHandlers.${name} is not available`);
	} else if(window['webkit']){
		console.warn('window.webkit.messageHandlers not available');
	} else {
		console.warn('window.webkit not available');
	}
};

export const setWebviewContainerHeight = (preferredHeight, isPercent=false) => {
	if(device === 'android' && window['PlatformInterface'] && window['PlatformInterface']['setPreferredHeight']) {
		window['PlatformInterface']['setPreferredHeight'](preferredHeight, isPercent);
	} else if(device === 'ios') {
		// sendIOSMessage('setPreferredHeight', JSON.stringify({ height: preferredHeight, unit: isPercent ? '%' : 'px' }));
		sendIOSMessage('setPreferredHeight', { height: preferredHeight, unit: isPercent ? '%' : 'px' });
	}
};

function isValidUrl(string, ignoreProtocolCheck=false) {
	let url; try { url = new URL(string); } catch (_) { return false; }
	return ignoreProtocolCheck || url.protocol === "http:" || url.protocol === "https:";
}

function injectScript(script, repeat) {
	try {
		const scriptElement = document.createElement('script');
		scriptElement.textContent = script;

		const iframes = document.querySelectorAll('iframe');
		for(let i=0; i<iframes.length; i++) {
			const doc = iframes[i].contentDocument || iframes[i].contentWindow.document;
			doc.body.appendChild(scriptElement);
			if(repeat) iframes[i].contentWindow.onpopstate = function() {
				injectScript(script);
			};
		}
	} catch (error) {
		console.error('Cannot access iframe content:', error);
	}
}

export const showAppWidget = () => {
	// const interfaceKeys = Object.keys(window['PlatformInterface'] || {});
	// console.error({ device, interfaceKeys, platformInterfaces: window['PlatformInterface'] });
	if(device === 'android' && window['PlatformInterface'] && window['PlatformInterface']['showAppWidget']) {
		window['PlatformInterface']['showAppWidget']();
	} else if(device === 'ios') {
		sendIOSMessage('showAppWidget', true);
	} else {
		console.error('Unsupported method call');
	}
};

export const runJavaScript = (script) => {
	if(device === 'android' && window['PlatformInterface'] && window['PlatformInterface']['runJavaScript']) {
		window['PlatformInterface']['runJavaScript'](script);
	} else if(device === 'ios') {
		sendIOSMessage('runJavaScript', script);
	} else {
		runJavaScriptInIFrame(script);
	}
};

export const runJavaScriptInIFrame = (script) => {
	try {
		let config = JSON.parse(script);
		const regex = /[^A-Za-z0-9 ]/g;
		let mode = (config["mode"] || "").replace(regex, '').toUpperCase();
		if (mode.replace(/\s+/g, "") === "NOREPEAT" && "script" in config) {
			injectScript(config['script']);
			return;
		} else if ("script" in config) {
			injectScript(config['script'], true);
			return;
		}
	} catch (_) {}
	injectScript(script, true);
};

export const openInNewTab = (url) => {
	if(!isValidUrl(url)) return;
	if(device === 'android' && window['PlatformInterface'] && (window['PlatformInterface']['openURL'] || window['PlatformInterface']['openInNewTab'])) {
		window['PlatformInterface'](['openURL'] || ['openInNewTab'])(url);
	} else if(device === 'ios') {
		sendIOSMessage('openInNewTab', url);
	} else {
		window.open(url, '_blank').focus();
	}
};

export const closeApp = () => {
	if(device === 'android' && window['PlatformInterface'] && window['PlatformInterface']['closeApp']) {
		window['PlatformInterface']['closeApp']();
	} else if(device === 'ios') {
		sendIOSMessage('closeApp', true);
	} else {
		window.close();
	}
};

export const handleFocus = (event) => {
	event.target.parentNode.classList.add('upi-widget-text-field-focused');
};

export const handleBlur = (event) => {
	event.target.parentNode.classList.remove('upi-widget-text-field-focused');
};

export const loadScript = (url) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = url;
		script.onload = resolve;
		script.onerror = reject;
		document.body.appendChild(script);
	});
};
